<template>
  <div class="pa-5">
    <v-card elevation="0" class="pa-5">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0">ปฏิทินนัดหมาย</h4>
      </div>
      <div class="d-flex justify-end">
        <v-btn
          :loading="loader"
          :disabled="loader"
          color="blue-grey"
          class="my-3 white--text"
          @click="addnew()"
        >
          <v-icon
            left
            dark
          >
            mdi-plus-circle
          </v-icon>
          เพิ่มนัด
        </v-btn>
      </div>
      <v-card-title class="pt-0 px-0">
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="4">
            <v-text-field
              v-model="filter_search"
              label="พิมพ์คำค้นหา"
              single-line
              hide-details
            >
              <v-icon
                slot="prepend"
                color="#182839"
              >
                mdi-magnify
              </v-icon>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="2">
            <v-menu
              ref="menuFilterDate"
              v-model="menuFilterDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="computedFilterDateFormatted"
                  label="วัน/เดือน/ปี"
                  placeholder="ระบุวัน/เดือน/ปี"
                  append-outer-icon="mdi-calendar-month"
                  readonly
                  required
                  @click:append-outer="menuFilterDate = true"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filterData.date"
                no-title
                @change="menuFilterDate = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="2">
            <v-select
              :items="itemsCategory"
              v-model="select_filter_category"
              item-text="name"
              item-value="name"
              label="ประเภทเคส"
              placeholder="เลืือกประเภท"
              no-data-text="ยังไม่มีข้อมูล"
              single-line
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="2">
            <v-select
              :items="itemsCustomer"
              v-model="select_filter_customer"
              item-text="name"
              item-value="name"
              label="ชื่อลูกค้า"
              placeholder="เลืือกลูกค้า"
              no-data-text="ยังไม่มีข้อมูล"
              single-line
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="2">
            <v-select
              :items="itemsStatus"
              v-model="select_filter_status"
              item-text="name"
              item-value="name"
              label="สถานะ"
              placeholder="เลืือกสถานะ"
              no-data-text="ยังไม่มีข้อมูล"
              single-line
              hide-details
            ></v-select>
          </v-col>
        </v-row>
      </v-card-title>
      <v-row class="fill-height">
        <v-col>
          <v-sheet height="64">
            <v-toolbar flat>
              <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
                วันนี้
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>รายวัน</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>รายสัปดาห์</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>รายเดือน</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </v-sheet>
          <v-sheet height="600">
            <v-calendar
              ref="calendar"
              v-model="focus"
              color="primary"
              :events="events"
              :event-color="getEventColor"
              :type="type"
              @click:event="showEvent"
              @click:more="viewDay"
              @click:date="viewDay"
              @change="updateRange"
            ></v-calendar>
            <v-menu
              v-model="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement"
              offset-x
            >
              <v-card color="grey lighten-4" min-width="350px" flat>
                <v-toolbar :color="selectedEvent.color" dark>
                  <v-btn icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon>
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <span v-html="selectedEvent.details"></span>
                </v-card-text>
                <v-card-actions>
                  <v-btn text color="secondary" @click="selectedOpen = false">
                    ยกเลิก
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-sheet>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog v-model="dialogNewAppointment" max-width="840px">
      <v-card>
        <v-card-title>
          <v-icon class="mr-3">mdi-plus-circle</v-icon>
          เพิ่มข้อมูลนัดหมาย
        </v-card-title>
        <v-card-text>
          <v-row class="mt-3 pa-0">
            <v-col cols="12" sm="12" md="6" lg="4" class="d-flex flex-row align-center">
              <span class="label-drudsandmedical-add-stock-incard mr-3">วันที่* :</span>
              <v-menu
                ref="menuCustomerDate"
                v-model="menuCustomerDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="computedAddDateFormatted"
                    label="วัน/เดือน/ปี*"
                    placeholder="ระบุวัน/เดือน/ปี"
                    append-outer-icon="mdi-calendar-month"
                    readonly
                    required
                    @click:append-outer="menuCustomerDate = true"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="customerData.date"
                  no-title
                  @change="menuCustomerDate = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="8" class="d-flex flex-row align-center">
              <v-menu
                ref="menuTimeBefore"
                v-model="menuTimeBefore"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="customerData.timebefore"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="customerData.timebefore"
                    label="ลงเวลาเริ่ม*"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menuTimeBefore"
                  v-model="customerData.timebefore"
                  :max="customerData.timeafter ? customerData.timeafter : null"
                  format="24hr"
                  full-width
                  @click:minute="$refs.menuTimeBefore.save(customerData.timebefore)"
                ></v-time-picker>
              </v-menu>
              <span class="label-drudsandmedical-add-stock-incard mx-3">ถึง</span>
              <v-menu
                ref="menuTimeAfter"
                v-model="menuTimeAfter"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="customerData.timeafter"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="customerData.timeafter"
                    label="ลงเวลาสิ้นสุด*"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menuTimeAfter"
                  v-model="customerData.timeafter"
                  :min="customerData.timebefore ? customerData.timebefore : null"
                  format="24hr"
                  full-width
                  @click:minute="$refs.menuTimeAfter.save(customerData.timeafter)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="4" class="d-flex flex-row align-center">
              <v-select
                :items="itemsCaseCategory"
                v-model="customerData.casecategory"
                label="ประเภทเคส*"
                placeholder="เลือกประเภทเคส"
                no-data-text="ยังไม่มีข้อมูล"
                :rules="selectRules"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="8" class="d-flex flex-row align-center">
              <v-select
                :items="itemsAppointmentMethod"
                v-model="customerData.appointmentmethod"
                label="ช่องทางการนัดหมาย*"
                placeholder="เลือกช่องทางการนัดหมาย"
                no-data-text="ยังไม่มีข้อมูล"
                :rules="selectRules"
                required
                class="mr-3"
              ></v-select>
              <v-text-field
                v-model="customerData.appointmentmore"
                label="รายละเอียดช่องทางการนัดหมาย*"
                placeholder="ระบุรายละเอียดช่องทางการนัดหมาย"
                :disabled="!customerData.appointmentmethod"
                outlined
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" class="d-flex flex-row align-center">
              <span class="label-drudsandmedical-add-stock-incard mr-3">บริการ* :</span>
              <v-select
                :items="itemsSevice"
                v-model="customerData.service"
                label="บริการ*"
                placeholder="เลือกบริการ"
                no-data-text="ยังไม่มีข้อมูล"
                :rules="selectRules"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="4" class="d-flex flex-row align-center">
              <span class="label-drudsandmedical-add-stock-incard mr-3">รหัสลูกค้า* :</span>
              <v-select
                :items="itemsCustomer"
                v-model="customerData.customer_id"
                label="รหัสลูกค้า*"
                placeholder="เลือกรหัสลูกค้า"
                no-data-text="ยังไม่มีข้อมูล"
                :rules="selectRules"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="8" class="d-flex flex-row align-center">
              <span class="label-drudsandmedical-add-stock-incard mr-3">ชื่อลูกค้า* :</span>
              <v-select
                :items="itemsCustomer"
                v-model="customerData.customer_name"
                label="ชื่อลูกค้า*"
                placeholder="เลือกชื่อลูกค้า"
                no-data-text="ยังไม่มีข้อมูล"
                :rules="selectRules"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" class="d-flex flex-row align-center">
              <span class="label-drudsandmedical-add-stock-incard mr-3">เบอร์โทรศัพท์* :</span>
              <v-text-field
                v-model="customerData.customer_phone"
                label="เบอร์โทรศัพท์*"
                placeholder="ระบุเบอร์โทรศัพท์"
                outlined
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" class="d-flex flex-row align-center">
              <span class="label-drudsandmedical-add-stock-incard mr-3">พนักงานผู้ติดต่อนัดหมาย* :</span>
              <v-select
                :items="itemsEmployee"
                v-model="customerData.employee"
                label="พนักงานผู้ติดต่อนัดหมาย*"
                placeholder="เลือกพนักงานผู้ติดต่อนัดหมาย"
                no-data-text="ยังไม่มีข้อมูล"
                :rules="selectRules"
                required
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="closeDialog">
            <v-icon class="mr-3">mdi-close-circle</v-icon>
            ยกเลิก
          </v-btn>
          <v-btn
            color="success"
            text
            @click="addDialog"
            :disabled="!canadd"
          >
            <v-icon class="mr-3">mdi-content-save</v-icon>
            เพิ่ม
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data: () => ({
    focus: '',
    type: 'month',
    typeToLabel: {
      month: 'รายเดือน',
      week: 'รายสัปดาห์',
      day: 'รายวัน'
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    colors: ['red', 'pink', 'purple', 'light-blue', 'gold', 'black', 'green darken-1'],
    names: ['เคสใหม่', 'เคสคอร์ส', 'เคสนัดติดตาม', 'เคสนัดติดตามแล้วเลื่อน', 'เคสรีวิว', 'เคสที่นัดเพื่อโทรครั้งใหม่', 'เคสที่นัดเพื่อโทรครั้งใหม่แล้วเลื่อน'],
    filterData: {
      date: new Date().toISOString().substr(0, 10)
    },
    menuFilterDate: false,
    itemsCategory: [],
    itemsCustomer: [],
    itemsStatus: [],
    filter_search: '',
    select_filter_category: '',
    select_filter_customer: '',
    select_filter_status: '',
    dialogNewAppointment: false,
    canadd: false,
    customerData: {
      date: new Date().toISOString().substr(0, 10),
      timebefore: null,
      timeafter: null
    },
    menuCustomerDate: false,
    menuTimeBefore: false,
    menuTimeAfter: false,
    itemsCaseCategory: ['เคสใหม่', 'เคสคอร์ส', 'เคสนัดติดตาม', 'เคสนัดติดตามแล้วเลื่อน', 'เคสรีวิว', 'เคสที่นัดเพื่อโทรครั้งใหม่', 'เคสที่นัดเพื่อโทรครั้งใหม่แล้วเลื่อน'],
    itemsAppointmentMethod: ['Facebook', 'Line', 'โทรศัพท์'],
    itemsSevice: [],
    itemsEmployee: []
  }),
  computed: {
    computedFilterDateFormatted () {
      const self = this
      return self.filterData.date ? moment(self.filterData.date).format('DD/MM/YYYY') : ''
    },
    computedAddDateFormatted () {
      const self = this
      return self.filterData.date ? moment(self.filterData.date).format('DD/MM/YYYY') : ''
    }
  },
  mounted () {
    this.$refs.calendar.checkChange()
  },
  methods: {
    viewDay ({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor (event) {
      return event.color
    },
    setToday () {
      this.focus = ''
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => {
          this.selectedOpen = true
        }, 10)
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    updateRange ({ start, end }) {
      const events = []

      const min = new Date(`${start.date}T00:00:00`)
      const max = new Date(`${end.date}T23:59:59`)
      const days = (max.getTime() - min.getTime()) / 86400000
      const eventCount = this.rnd(days, days + 20)

      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0
        const firstTimestamp = this.rnd(min.getTime(), max.getTime())
        const first = new Date(firstTimestamp - (firstTimestamp % 900000))
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
        const second = new Date(first.getTime() + secondTimestamp)

        events.push({
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: first,
          end: second,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: !allDay
        })
      }

      this.events = events
    },
    rnd (a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    clearDialog () {
      const self = this
      self.customerData = {
        date: new Date().toISOString().substr(0, 10),
        timebefore: null,
        timeafter: null
      }
    },
    addnew () {
      const self = this
      self.dialogNewAppointment = true
    },
    closeDialog () {
      const self = this
      self.dialogNewAppointment = false
      self.clearDialog()
    },
    addDialog () {
      const self = this
      self.dialogNewAppointment = false
      self.clearDialog()
    }
  }
}
</script>
